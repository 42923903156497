<template>
  <div>
    <!-- 股票信息 -->
    <div class="banner" id="investor_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- 投资者关系图片 -->
      <!-- <img :src="banner" /> -->
      <span
        >{{ $t("indexnav.Stock Chart")
        }}<i
          :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }"
          >Stock Chart</i
        ></span
      >
    </div>
    <!-- 跳转 -->
    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a> >
      <a href="/ir/default.html">{{ $t("indexnav.Investor Relations") }}</a> >
      <a>{{ $t("indexnav.Stock Chart") }}</a> -->
      <bread-crumb />
    </section>
    <!-- 选择日期 -->
    <!-- <div id="iframeContainer"></div> -->
    <div style="text-align: center;margin-top: 20px;">
      <iframe
      v-if="nowi=='zh'"
        id="iframeContainer"
        src="https://stockchart2.website.wisdomir.com/main/index.php?id=485&lang=sc#xdm_e=http%3A%2F%2Fwww.jxr-fertility.com&xdm_c=default8348&xdm_p=1"
        frameborder="0"
      ></iframe>
      <iframe
       v-if="nowi=='tr'"
        id="iframeContainer"
        src="https://stockchart2.website.wisdomir.com/main/index.php?id=485&lang=sc#xdm_e=http%3A%2F%2Flocalhost%3A888&xdm_c=default93998&xdm_p=1"
        frameborder="0"
      ></iframe>
      <iframe
       v-if="nowi=='en'"
        id="iframeContainer"
        src="https://stockchart2.website.wisdomir.com/main/index.php?id=485&lang=en#xdm_e=http%3A%2F%2Flocalhost%3A888&xdm_c=default93998&xdm_p=1"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
// 接口
import { Stock } from "@/api/investors";
import stockmsgcomponent from "@/components/stockmsgcomponent.vue";
import { changeStock, formatDate } from "@/utils/util";
import { PageType } from "@/utils/constant";
import { getAction } from "@/api/manage";
import { ifarmeMixin } from "../mixin/ifarmeMixin";
export default {
  mixins: [ifarmeMixin],
  data() {
    return {
      dataList: {},
      // 股票编号
      Gid: "",
      // 成交额
      // sss: changeStock(296820),
      year: "", // 年
      month: "", // 月
      day: "", // 天  http://website.jinxinfertility.wisdomir.com/sc/ir_prospectus.php"
      banner: "",
      izh: "https://stockchart2.website.wisdomir.com/main/index.php?id=485&lang=sc#xdm_e=http%3A%2F%2Fwww.jxr-fertility.com&xdm_c=default8348&xdm_p=1",
      itr: "https://stockchart2.website.wisdomir.com/main/index.php?id=485&lang=sc#xdm_e=http%3A%2F%2Flocalhost%3A888&xdm_c=default93998&xdm_p=1",
      ien: "https://stockchart2.website.wisdomir.com/main/index.php?id=485&lang=en#xdm_e=http%3A%2F%2Flocalhost%3A888&xdm_c=default93998&xdm_p=1",
      nowi:''
      //     izh: "https://stockchart2.website.wisdomir.com/main/index.php",
      // itr: "https://stockchart2.website.wisdomir.com/main/index.php",
      // ien: "https://stockchart2.website.wisdomir.com/main/index.php",
    };
  },
  created() {
     if (this.$store.state.nowlanguage == "zh") {
      this.nowi='zh'
    }
    if (this.$store.state.nowlanguage == "tr") {
      this.nowi='tr'
    }
    if (this.$store.state.nowlanguage == "en") {
      this.nowi='en'
    }
    // 初始化banner
    let that = this;
    getAction("/jeecg-system/common/frontend/advertise/active_list", {
      typeCode: PageType.GUPIAOXINXI,
    }).then((res) => {
      if (
        res.result.records instanceof Array &&
        res.result.records.length > 0
      ) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg;
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });

    Stock().then((res) => {
      this.dataList = res.result;
      // 转换百万单位--成交额
      res.result.traNumber = changeStock(res.result.traNumber);
      // 转换百万单位--成交额
      res.result.traAmount = changeStock(res.result.traAmount);
      this.year = res.result.date.slice(0, 4);
      this.month = res.result.date.slice(5, 7);
      this.day = res.result.date.slice(8, 11);

      console.log(this.year, this.month, this.day);
      let Giden = "";
      let Gidnum = "";
      // this.dataList = res.result;
      // 转换格式
      // 获取最后一个字符串'0'的索引
      let Gidenindex = "hk01951".toUpperCase().lastIndexOf("0");
      // let Gidenindex = "hk01951".toUpperCase().IndexOf("0");
      // 截取Gidenindex之前的字符串 HK
      Giden = "HK01951".substring(0, Gidenindex);
      // 截取Gidenindex之前的字符串 1951
      Gidnum = "HK01951".substring(Gidenindex + 1);
      // 把拼接好的字符串赋值给 Gid:转换完格式的股票编号
      this.Gid = Gidnum + "." + Giden;
    });
  },
  mounted() {
    // if (this.$store.state.nowlanguage == "zh") {
    //   this.nowi=this.izh
    //   this.setIframe(this.izh);
    // }
    // if (this.$store.state.nowlanguage == "tr") {
    //   this.nowi=this.itr
    //   this.setIframe(this.itr);
    // }
    // if (this.$store.state.nowlanguage == "en") {
    //   this.nowi=this.ien
    //   this.setIframe(this.ien);
    // }
  },
};
</script>

<style lang='less' scoped>
strong {
  color: #18325f;
}
.banner {
  position: relative;
  > img {
    width: 100%;
    max-width: 100vw;
    display: block;
  }
}

#iframeContainer {
  height: 1000px;
  margin: 0 auto 0 auto;
  width: 100%;
  max-width: 1200px;
}
#header {
  position: relative;
  max-width: 1920px;
  /* min-width: 320px; */
  overflow: hidden;
  height: auto;
}
.container {
  padding: 0 1.5em 1.5em 0.7em;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}
.head_top {
  justify-content: space-between;
  font: 16px/1.6 "Calibri", Arial, Helvetica, sans-serif, Verdana;
  margin: 3em auto 0 auto;
}
.head_time {
  font-size: 14px;
  line-height: 1.2;
  color: #808080;
}
.head_top > div {
  position: relative;
  align-items: inherit;
}
.head_logo {
  line-height: 1;
  font-size: 24px;
  padding-bottom: 5px;
  color: #808080;
}
.head_logo strong {
  color: #808080;
}
.flex {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
}
.head_min {
  position: relative;
  margin-top: 20px;
}
.head_bot {
  position: relative;
  margin: 20px 0;
  strong {
    color: #808080 !important ;
  }
}
ul.range_ul {
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
}
ul.range_ul li {
  position: relative;
  padding: 10px 0px;
  width: 45%;
  border-bottom: 1px solid #c1c1c1;
  text-transform: uppercase;
  > div {
    > strong {
      font-weight: bolder !important;
      font: 16px/1.6 "Calibri", Arial, Helvetica, sans-serif, Verdana;
    }
    > span {
      font: 16px/1.6 "Calibri", Arial, Helvetica, sans-serif, Verdana;
      color: #808080;
    }
  }
}
ul.hkd_ul li {
  position: relative;
  width: 100%;
  margin: 0 3px;
  text-align: center;
  background: #f2f2f2;
  span {
    color: #808080;
  }
}
.up::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-right: 6px solid transparent;
  border-bottom: 10px solid #18d405;
  border-left: 6px solid transparent;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -mmoz-transform: translateY(-50%);
}
ul.hkd_ul li:first-child {
  margin-left: 0;
  min-width: 280px;
}
ul.hkd_ul li div {
  position: relative;
  width: 100%;
  padding: 9px 0px;
  align-items: center;
}
ul.hkd_ul li div span {
  padding: 0px 15px;
  min-width: 30%;
  text-align: left;
}
ul.hkd_ul li strong.str_hkd {
  font-size: 44px;
  width: 100%;
  text-align: right;
  padding-left: 0;
}
div.bor_bot {
  border-bottom: 3px solid #ffffff;
}
.justify_between_center {
  justify-content: space-between;
  align-items: center;
}
.justify_between_stretch {
  justify-content: space-between;
}
@media screen and (max-width: 1280px) {
  ul.hkd_ul li strong {
    font-size: 22px;
  }
}
@media screen and (max-width: 1024px) {
  ul.hkd_ul li strong {
    font-size: 26px;
  }
  ul.hkd_ul {
    flex-wrap: wrap;
  }
  ul.hkd_ul li {
    width: 49.5%;
    margin: 0 0 6px 0 !important;
  }
}
@media screen and (max-width: 600px) {
  .head_top {
    display: block;
  }
  ul.hkd_ul li {
    width: 100%;
    margin-bottom: 6px;
    padding: 10px 0px;
  }
  ul.range_ul li {
    width: 100%;
  }
  ul.hkd_ul li strong.str_hkd {
    font-size: 40px;
  }
}
@media screen and (max-width: 640px) {
  .banner span {
    font-size: 2rem;
  }
}
ul.hkd_ul li strong {
  position: relative;
  font: 22px "STHeiti", "Microsoft JhengHei", "Microsoft YaHei", "宋体", Arial,
    Helvetica, Verdana, sans-serif;
  font-weight: normal;
  padding: 0px 15px 0 25px;
  line-height: 1;
  /* font-family: "Calibri", Arial, Helvetica, sans-serif, Verdana; */
}
div.com_height {
  /* height: 100%; */
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -mmoz-transform: translateY(-50%);
}
</style>
