<template>
  <div>
    <!--股票信息折线图组件 -->
    <section class="container">
      <div class="chat_main">
        <div class="stock_chat" id="stock_chat">
          <div id="range_selector">
            <ul class="range_selector_ul flex justify_between_center">
              <li id="button1" class="active">
                <a
                  href="javascript:void(0);"
                  class="stockcolor-bg"
                  @click="changeDate(30)"
                  >{{ $t("stockmsg.1个月") }}</a
                >
              </li>
              <li id="button2" class="">
                <a
                  href="javascript:void(0);"
                  class="stockcolor-bg"
                  @click="changeDate(90)"
                  >{{ $t("stockmsg.3个月") }}</a
                >
              </li>
              <li id="button3" class="">
                <a
                  href="javascript:void(0);"
                  class="stockcolor-bg"
                  @click="changeDate(365)"
                  >{{ $t("stockmsg.1年") }}</a
                >
              </li>
              <li id="button4" class="">
                <a
                  href="javascript:void(0);"
                  class="stockcolor-bg"
                  @click="changeDate(365)"
                  >{{ $t("stockmsg.今年") }}</a
                >
              </li>
              <li id="button5">
                <a
                  href="javascript:void(0);"
                  class="stockcolor-bg"
                  @click="changeDate(0)"
                  >{{ $t("stockmsg.全部") }}</a
                >
              </li>
            </ul>
          </div>
          <!-- 日期选择 -->
          <div class="date_check">
            <div class="block">
              <span class="demonstration">由</span>
              <el-date-picker
                v-model="date1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="mini"
                value-format="yyyy-MM-dd"
                @change="chooseTimeRange"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
      <!-- 折线图 -->
      <div id="main" style="width: 100%; height: 300px"></div>
    </section>
  </div>
</template>

<script>
// 引入eachrts
// import echarts from "echarts";
import echarts from "echarts";
import { Stock, Stockimg } from "@/api/investors";

export default {
  created() {
    console.log(this.dateList);
  },
  // props:{
  //    lineData:Array,
  //   lineTime:Array,
  // },
  data() {
    return {
      date1: "",
      dataList: [],
      dateList: [],
      DateNum: 20,
    };
  },
  methods: {
    changeDate(Date) {
      this.DateNum = Date;
      this.zhexiantu2();
      console.log(this.DateNum);
    },
    chooseTimeRange() {
      console.log(this.date1);
    },
    zhexiantu2() {
      let dataList = this.dataList;
      let dateList = JSON.parse(JSON.stringify(this.dateList));
      console.log(dateList);
      let dateNum = 0;
      if (this.DateNum != 0) {
        dateNum = 100 - (this.DateNum / dateList.length) * 100;
      } else if (this.DateNum == 0) {
        dateNum = 0;
      }
      let rdata = [];
      for (let i of dateList) {
        rdata.push(i);
      }
      console.log(rdata);
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;
      // let data = [[base, Math.random() * 300]];
      // for (let i = 1; i < 20000; i++) {
      //   let now = new Date((base += oneDay));
      //   data.push([
      //     +now,
      //     Math.round((Math.random() - 0.5) * 20 + data[i - 1][1]),
      //   ]);
      // }
      option = {
        dataset: [
          {
            id: "x_data",
            source: dateList,
          },
          {
            id: "x_dataFilter",
          },
        ],
        tooltip: {
          trigger: "axis",
          position: function (pt) {
            return [pt[0], "10%"];
          },
          areaStyle: {
            color: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"], // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色
            opacity: 1, // 图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形
          },
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            restore: {},
            saveAsImage: {},
          },
        },
        xAxis: {
          // boundaryGap: false,、
          // data: rdata,
          min: "2022-08-10",
          max: "2022-08-18",
          type: "time",
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          // type: "value",
          boundaryGap: [0, "100%"],
        },
        grid: {
          top: 35,
          left: 0,
          right: 0,
          height: "auto",
        },
        dataZoom: [
          // 拖拽条开始结束位置
          {
            type: "inside",
            start: dateNum,
            end: 100,
            throttle: 100,
          },
          {
            start: 0,
            end: 10,
          },
        ],
        // ‘circle’, ‘rect’, ‘roundRect’, ‘triangle’, ‘diamond’, ‘pin’, ‘arrow’, ‘none’
        series: [
          {
            name: "价格",
            type: "line",
            symbol: "circle", // 实心圆点
            color: "rgb(124, 181, 236)",
            buttonColor: "#c23531",
            areaStyle: {
              color: "#fff",
            },
            lineStyle: {
              color: "#88B9EA",
            },
            // data:[["2022-08-10",10],["2022-08-11",20],["2022-08-12",30],["2022-08-13",40],["2022-08-14",50],["2022-08-15",60],["2022-08-16",70],["2022-08-17",80],["2022-08-18",90]],
           data:dateList,
           smooth: false,
          },
        ],
      };

      option && myChart.setOption(option);
    },

    // initEcharts: function () {
    //   const Charts = document.getElementById("myCharts"); //getDom
    //   const myCharts = echarts.init(Charts); // add
    //   myCharts.clear(); //清空画布
    //   myCharts.setOption({
    //     title: {
    //       text: "收支统计金额（元）", //标题
    //       x: "10px",
    //       textStyle: {
    //         //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
    //         fontSize: 14,
    //       },
    //     },
    //     tooltip: {
    //       trigger: "axis",
    //       // formatter: '{b}<br/>{a0}：{c0}元<br />{a1}：{c1}元<br />{a2}：{c2}元'
    //     },
    //     legend: {
    //       y: "20px",
    //       textStyle: {
    //         //图例文字的样式
    //         color: "#6a6da9",
    //         fontSize: 13,
    //       },
    //     },
    //     xAxis: {
    //       type: "category",
    //       data: this.dateList,
    //       axisLabel: {
    //         // show: false,//不显示坐标轴上的文字
    //         // rotate:40
    //       },
    //     },
    //     dataZoom: [
    //       {
    //         textStyle: {
    //           fontSize: 11,
    //         },
    //       },
    //     ],
    //     yAxis: {
    //       splitLine: {
    //         show: false,
    //       },
    //       type: "value",
    //       splitNumber: 5,
    //     },
    //     //折线
    //     series: [
    //       {
    //         data: this.dataList,
    //       },
    //     ],
    //   });
    // },
  },
  mounted() {
    // 股票趋势图
    Stockimg().then((res) => {
      console.log(res);
      let DateResList = [];
      let DataResList = [];
      // this.dataList=res.result
      for (let i of res.result) {
        console.log(i);
        this.dateList.push([i.date,Number(i.pri)]);
        DateResList.push(i.date);
        DataResList.push(i.pri);
      }
      console.log(this.dateList );
      // this.dateList = DateResList;
      this.dataList = DataResList;

      this.zhexiantu2();
    });
    // this.initEcharts();
  },
  //折线数据通过变量传递
  watch: {
    lineData: {
      deep: true,
      handler(val) {
        if (val) {
          setTimeout(this.initEcharts(), 100);
        }
      },
    },
  },
};
</script>

<style scoped lang=less>
.container {
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0px auto;
  padding: 0px 20px;
  padding: 0px;
  margin: 3em auto 0 auto;
  color: #808080;
}
.chat_main {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 40px;
}
.stock_chat {
  position: relative;
  /* min-height: 400px; */
}
#range_selector {
  position: relative;
  width: 38%;
}
ul.range_selector_ul {
  display: table;
  table-layout: fixed;
  display: flex;
}
/* .justify_between_center {
  justify-content: space-between;
  align-items: center;
} */
ul.range_selector_ul li {
  text-align: center;
  width: 100%;
  background: #18325f;
  margin-right: 2px;

  a {
    padding: 7px 1.3em;
    display: block;
    margin: 0 2px 2px 0 !important;
    color: #fff;
    text-decoration: none;
  }
}
ul.range_selector_ul li:hover {
  background-color: #808080;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}
/* 选择日期 */
.date_check {
  display: flex;
  justify-content: end;
}
/deep/ .block {
  margin: 1em 2em 0 0;
}
#main {
  :first-child {
    width: 100% !important;
  }
  #zr_0 {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  ul.range_selector_ul li {
    width: 30%;
    margin-top: 2px;
  }
  ul.range_selector_ul {
    display: flex;
    flex-wrap: wrap;
  }
  #zr_0 {
    width: 100% !important;
  }
}
@media screen and (max-width: 1024px) {
  #range_input,
  #range_selector {
    width: 100%;
  }
  ul.range_selector_ul li {
    flex: 1;
    margin-top: 2px;
    > a {
      padding: 8px 18px;
      display: block;
      margin: 0 2px 2px 0 !important;
      color: #fff;
    }
  }
}
</style>
